// import FastTitle from '@/components/fast-title.vue'
import NewsGuide from '@/components/news-guide.vue'   //新闻指南
// import Vcode from 'vue-puzzle-vcode' // 图形验证码
// ElConfigProvider 组件
// import { ElConfigProvider } from 'element-plus'
const comComponents = {
    install: function(Vue) {
        // 注册并获取组件，然后在main.js中引用，在Vue.use()就可以了
        // Vue.component('FastTitle', FastTitle)
        Vue.component('NewsGuide', NewsGuide)
        // Vue.component('Vcode', Vcode)
        // Vue.component('ElConfigProvider', ElConfigProvider)
    }
}
export default comComponents