import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入element-plus ui 
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
// 引入中文包
// import locale from "element-plus/lib/locale/lang/zh-cn";
// import en from "element-plus/lib/locale/lang/en"; 

// 引入全局注册组件
import comComponents from '@/utils/comComponents.js'



// 网络请求 
import http from '@/utils/http.js'

import UUID from "vue-uuid"

import 'amfe-flexible';

import "./utils/reset.css";
import "./utils/common.css";
const app = createApp(App)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(comComponents)
app.use(UUID)
app.config.globalProperties.$http = http;
app.mount('#app')


