const url = {
    // 新闻表列表
	getNewsList: {
		path: '/web/invest/common/news/list',
		method: 'get'
	},
	getNewsById: {
		path: '/web/invest/common/news',
		method: 'get'
	},
	// 发送验证码
	getValidCode: {
		path: '/web/invest/common/caseInfoSearchlog/sendSmsCode',
		method: 'get'
	},
	// 新增客户查询记录
	caseInfoSearchlog: {
		path: '/web/invest/common/caseInfoSearchlog',
		method: 'post'
	},
	
	// 根据查询标记查询列表
	getCaseList: {  
		path: '/web/invest/common/caseInfoSearchlog/findByFlag',
		method: 'get'
	}, 
	// 获取案件详细信息 
	getCaseDetail: {  
		path: '/web/invest/common/caseInfoSearchlog',
		method: 'get'
	}, 
	
	
}
export default url