<template>
    <div class="page-content white-bg">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="help-peo flex">
            <div class="help-nav">
                <ul class="nav-list">
                    <li
                        class="hand"
                        :class="newTypeId == item.typeId ? 'on' : ''"
                        v-for="(item, index) in titleList"
                        :key="index"
                        @click="skipNav(item, index)"
                    >
                        {{ item.title }}
                    </li>
                </ul>
            </div>

            <div class="help-detail">
                <div class="box-help" v-show="!showDetail">
                    <div class="gray-module flex">
                        <img src="@/assets/images/xx.png" alt="" />
                        <h3>{{ title }}</h3>
                    </div>
                    <div class="list-wrap">
                        <div class="lawsWrap">
                            <ul class="">
                                <li
                                    class="between hand"
                                    v-for="(item, index) in dataList"
                                    :key="index"
                                    @click="skipDetail(item)"
                                >
                                    <span>{{ item.title }}</span>
                                    <span>点击查看&gt;&gt;</span>
                                </li>
                            </ul>
                        </div>

                        <div class="listpage center">
                            <el-pagination
                                layout="prev, pager, next"
                                :total="total"
                                @current-change="onPageChange"
                                @size-change="onPageSizeChange"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <div class="box-help" v-show="showDetail">
                    <div class="gray-module flex">
                        <img src="@/assets/images/xx.png" alt="" />
                        <h3>{{ contentTitle }}</h3>
                    </div>
                <!-- 调解员名录详情 -->
                    <div
                        class="content-wrap letter1px"
                        v-if="newTypeId == '10000056'"
                    >
                        <img src="@/assets/images/tjy-md.jpg" alt="" />
                    </div>
                    <div
                        class="content-wrap letter1px"
                        v-else
                        v-html="content"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import app from "../App.vue";

export default {
    name: "mediation-center",

    props: {
        typeId: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            title: "调解中心简介",
            titleList: [
                {
                    title: "调解中心简介",
                    typeId: "10000059",
                },
                {
                    title: "调解员名录",
                    typeId: "10000056",
                },
                {
                    title: "新闻动态指南",
                    typeId: "10000057",
                },
                {
                    title: "诉讼服务指南",
                    typeId: "10000058",
                },
                {
                    title: "调解指南",
                    typeId: "10000055",
                },
            ],
            dataList: [],
            showDetail: false,
            page: 1,
            total: 10,
            contentTitle: "",
            content: "",
            newTypeId: this.typeId,
        };
    },

    async created() {
        // 当前导航title
        this.getTitle();
        if (this.id) {
            this.showDetail = true;
            return this.getNewsById(this.id);
        }
        this.getData();
    },

    methods: {
        getTitle() {
            this.titleList.forEach((item, index) => {
                if (item.typeId == this.newTypeId) {
                    this.title = item.title;
                }
            });
        },

        async getData() {
            let params = {
                pageSize: 10,
                pageNum: this.page,
                typeId: this.newTypeId,
            };
            const rsp = await this.$http("getNewsList", params);
            if (rsp.code != 200)
                return this.$message({ message: rsp.message, type: "warning" });
            this.total = rsp.data.total;
            this.dataList = rsp.data.records;
        },

        skipNav(item, index) {
            this.title = item.title;
            this.showDetail = false;
            this.newTypeId = item.typeId;
            sessionStorage.setItem("typeId", item.typeId);

            // console.log(" app --2222222222", app);
            app.methods.getTypeId(item.typeId);
            this.getData();
        },
        skipDetail(item) {
            this.showDetail = true;
            this.getNewsById(item.id);
        },

        async getNewsById(id) {
            let params = {
                id: id,
            };
            const rsp = await this.$http("getNewsById", params);
            if (rsp.code != 200)
                return this.$message({ message: rsp.message, type: "warning" });
            this.total = rsp.data.total;
            this.content = rsp.data.content;
            this.contentTitle = rsp.data.title;
        },
        // 页码改变函数
        onPageChange(currentPage) {
            console.log("currentPage", currentPage);
            this.page = currentPage;
            this.getData();
        },

        onPageSizeChange() {},
    },
};
</script>
<style lang="less" scoped>
.page-content {
    width: 1060px;
    margin: 20px auto;
    .el-breadcrumb {
        padding: 20px;
        border-bottom: 1px solid #f4f4f4;
    }
    .help-peo {
        min-height: 640px;
        .help-nav {
            width: 185px;
            ul {
                width: 145px;
                margin-top: 15px;
                margin-left: 20px;
                li {
                    height: 38px;
                    width: 145px;
                    line-height: 38px;
                    text-align: center;
                    color: #000;
                    border-bottom: 1px solid #f4f4f4;
                    font-size: 14px;
                }
                .on {
                    background-color: #f4f4f4;
                    font-weight: 700;
                }
            }
        }
        // 列表
        .help-detail {
            // width: 874px;
            border-left: 1px solid #f4f4f4;
            padding: 0 0 0 15px;
            flex: 1;
            .box-help {
                padding-bottom: 20px;
                margin-top: 15px;
                width: 845px;
                .gray-module {
                    padding: 10px 18px;
                    background-color: #f8f8f8;
                    align-items: center;
                    img {
                        width: 18px;
                        height: 15px;
                        margin-right: 10px;
                    }
                    h3 {
                        font-weight: 400;
                        width: 780px;
                        font-size: 16px;
                    }
                }
                // 数据列表
                .list-wrap {
                    .lawsWrap {
                        min-height: 500px;
                    }
                    ul {
                        padding: 10px 18px;
                        li {
                            height: 40px;
                            line-height: 40px;
                            border-bottom: 1px solid #f4f4f4;
                            color: #000;
                            font-size: 14px;
                            span:nth-child(1) {
                                width: 700px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        li:hover {
                            color: red;
                        }
                    }
                }
            }
            // 详情
            .content-wrap {
                padding: 10px 18px;
                color: #333;
                font-size: 14px;
                /deep/ img {
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
