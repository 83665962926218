import { createRouter, createWebHistory } from "vue-router";
import home from "../views/home/home.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: home,
	},
	// 首页服务指南 see-more
	{
		path: "/see-more",
		name: "see-more",
		component: () => import("../views/see-more/index.vue"),
	},
	// 调解中心
	{
		path: "/mediation-center",
		name: "mediation-center",
		component: () => import("../views/mediation-center/index.vue"),
	
	},
	// 调解指南 
	{
		path: "/mediation-guide",  
		name: "mediation-guide",
		component: () => import("../views/mediation-guide/index.vue"),
	
	},
	// 调解员名录 
	{
		path: "/mediator-list",  
		name: "mediator-list",
		component: () =>
		import("../views/mediator-list/index.vue"),
	},
	{
		path: "/court",
		name: "court",
		component: () => import("../views/court/index.vue"),
		
	},
	// 查询案件  
	{
		path: "/case",
		name: "case",
		// component: () => import("../views/case/index.vue"),
		children: [
			{
				path: 'index',
				component: () =>
				import("../views/case/index.vue"),  
			},
			{
				path: 'list',
				component: () =>
				import("../views/case/list.vue"),  
			},
			{
				path: 'details',
				component: () =>
				import("../views/case/details.vue"),  
			},

		]
	},
	
	
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
