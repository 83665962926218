
// 封装请求方法

import api from './api.js'
// import { Message, Loading } from 'element-ui'
import { ElMessage, Loading } from "element-plus";
import axios from 'axios'
import Qs from 'qs'

// 全局参数
// import GLOBAL from '@/utils/global-variable.js'
let GLOBAL = {
	// baseURL: 'http://47.242.114.116:13307',  //测试环境
	// baseURL: 'http://8.137.12.66:13307'  //成都法正正式环境
	baseURL: 'https://api.hbgytj.com'  //蕲春县公言正式环境
	
}

import router from '@/router'



axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;
//设置cross跨域 并设置访问权限 允许跨域携带cookie信息axio.defaults.headers.common['Authorization'] = ''; // 设置请求头为 Authorizationreturn axio.post(PATH.FINANCE_APL_LIST,data, {withCredentials:true}).then(res=>{console.log("OKOKOK2-------------------",res)return res.data.retObj})
// 创建axios实例
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,  // url = base url + request url
	baseURL: GLOBAL.baseURL,
	// withCredentials: true, // 跨域请求时发送cookies . flase 为不发送cookies
	// crossDomain: true,
	timeout: 5000 // 请求超时
})

//  请求拦截器
service.interceptors.request.use(
	config => {
		const userToken = localStorage.getItem('userToken')
		config.headers['userToken'] = userToken
		return config
	},
	error => {
		// 请求错误
		console.log('请求错误', error)
		return Promise.reject(error)
	}
)


// 响应拦截器
service.interceptors.response.use(
	response => {
		const res = response.data
		// 如果自定义代码不是200，则判断为错误。
		if (res.code == '10010001') {
			localStorage.clear()
			return router.push(`/`)
		} else {
			return res
			// return Promise.resolve(res)
		}
	},
	error => {
		console.log('err' + error) // for debug
		ElMessage({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

/**
 * api请求方法
 *
 * @param {String} url          接口
 * @param {Object} params       提交数据
 */

const apiRequest = (url, params, data, authserver, assess, logistics, safe) => {
	const method = api[url].method
	url = `${GLOBAL.baseURL}${api[url].path}`


	// const loading = Loading.service({
	// 	lock: true,
	// 	// text: 'Loading',
	// 	spinner: 'el-icon-loading',
	// 	background: 'rgba(0, 0, 0, 0.1)'
	// })
	return new Promise((resolve, reject) => {
		service({
			method: method,
			url: url,
			// data: method != 'get' ? Qs.stringify(params) : null,
			data: method != 'get' ? JSON.stringify(params) : null,
			params: method == 'get' ? params : null
		}).then(res => {
			resolve(res)
			// loading.close()
		}).catch(err => {
			reject(err)
			// loading.close()
		})
	})
}
export default apiRequest

